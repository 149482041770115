import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    /*{
      title: 'List',
      url: '/list',
      icon: 'list'
    },
    */
    /*{
      title: 'Ejemplo',
      url: '/ejemplo',
      icon: 'list'
    },
    {
      title: 'Reporte',
      url: '/reporte01',
      icon: 'list'
    },*/
    {
      title: 'Open Invoice',
      url: '/reporte02',
      icon: 'md-copy'
    },
    {
      title: 'Payments',
      url: '/reporte03',
      icon: 'ios-cash'
    },
    {
      title: 'Log Payments',
      url: '/reporte04',
      icon: 'md-clock'
    },
    {
      title: 'Credit Notes',
      url: '/reporte05',
      icon: 'ios-list-box'
    }


  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public storage: Storage,
    public router: Router,
    public toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      setInterval(() => {
        this.storage.get('CURRENTUSER').then(
          (data) => {
            if (data != null) {
              /*let actualTime = new Date().getTime();
              let sessionDifTome = (actualTime - data.sessiontime);
              console.log('tiemepo de session', sessionDifTome);
              if (sessionDifTome > 30000) {
                console.log('Cerrada');                
                this.storage.remove('CURRENTUSER').then(
                  (data) => {
                    this.alertaErrorLogin('Se ha cerrado la sesion por tiempo de inactividad');
                    this.router.navigate(['login'], {replaceUrl: true});
                  }
                );
              }*/
            }
          }
        );
      }, 10000);
    });
  }

  async alertaErrorLogin(mensaje: string) {
    const toast = await this.toastCtrl.create({
      color: 'warning',
      message: mensaje,
      showCloseButton: true
    });
    toast.present();
  }

  cerrarsesion() {
    this.storage.remove('CURRENTUSER').then(
      () => {
        this.router.navigate(['login'], { replaceUrl: true });
      }
    );
  }

  ngOnInit() {
  }
}
