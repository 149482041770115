import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'ejemplo',
    loadChildren: () => import('./pages/ejemplo/ejemplo.module').then( m => m.EjemploPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reporte01',
    loadChildren: () => import('./pages/reporte01/reporte01.module').then( m => m.Reporte01PageModule)
  },
  {
    path: 'reporte02',
    loadChildren: () => import('./pages/reporte02/reporte02.module').then( m => m.Reporte02PageModule)
  },
  {
    path: 'reporte03',
    loadChildren: () => import('./pages/reporte03/reporte03.module').then( m => m.Reporte03PageModule)
  },
  {
    path: 'reporte04',
    loadChildren: () => import('./pages/reporte04/reporte04.module').then( m => m.Reporte04PageModule)
  },
  {
    path: 'reporte05',
    loadChildren: () => import('./pages/reporte05/reporte05.module').then( m => m.Reporte05PageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
